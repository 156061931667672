import React, { createContext, useState, useContext } from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';

const ModalContext = createContext();

const StyledModal = styled(Modal)`
  position: absolute;
  top: 45%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  color: white;
  background-color: #013881;
  border-radius: 20px;
  padding: 20px;
  width: 80%;
  height: 210px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  h2 {
    font-size: 21px;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  label {
    margin-top: 10px;
  }
  input {
    margin-top: 5px;
  }
  button {
    margin-top: 10px;
    border: none;
    background-color: white;
    color: black;
    border-radius: 15px;
    font-weight: bold;
    width: 100px;
    font-family: "Montserrat", sans-serif;
  }
`;

const CustomPackageForm = ({ isOpen, onRequestClose, name, setName, origin, setOrigin, destiny, setDestiny, orcamento }) => (
  <StyledModal isOpen={isOpen} onRequestClose={onRequestClose}>
    <h2>Peça um Orçamento Personalizado</h2>
    <Form onSubmit={orcamento}>
      <label>
        Nome:
      </label>
      <input type="text" name="name" id='name' value={name} onChange={(e) => setName(e.target.value)} required />
      <label>
        Cidade de origem:
      </label>
      <input type="text" name="origem" id='origem' value={origin} onChange={(e) => setOrigin(e.target.value)} required />
      <label>
        Cidade de destino:
      </label>
      <input type="text" name="destino" id='destiny' value={destiny} onChange={(e) => setDestiny(e.target.value)} required />
      <button type="submit">ENVIAR</button>
    </Form>
  </StyledModal>
);

export const ModalProvider = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [name, setName] = useState('');
  const [origin, setOrigin] = useState('');
  const [destiny, setDestiny] = useState('');

  function orcamento(e) {
    e.preventDefault();
    let message = `
    Olá meu nome é ${name}!
    Gostaria de saber informações de passagens saindo de ${origin}
    e indo para ${destiny}
    `;
    const encode = encodeURIComponent(message);
    window.open(`https://wa.me/5532984957373?text=${encode}`);
    setName('');
    setOrigin('');
    setDestiny('');
  }

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <ModalContext.Provider value={{ isModalOpen, openModal, closeModal }}>
      {children}
      <CustomPackageForm 
        isOpen={isModalOpen} 
        onRequestClose={closeModal} 
        name={name} 
        setName={setName} 
        origin={origin} 
        setOrigin={setOrigin} 
        destiny={destiny} 
        setDestiny={setDestiny} 
        orcamento={orcamento} 
      />
    </ModalContext.Provider>
  );
};

export const useModal = () => useContext(ModalContext);
