import styled from "styled-components";

export const Packet = styled.div`
height: 320px;
width: 270px;
margin-top: 40px;
background: #FFFFFF;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 10px;
display: flex;
flex-direction: column;
margin-bottom: 20px;
@media(min-width:900px){
    height: 400px;
width: 350px;
}
`
export const Image = styled.div`
background-image: url(${props => props.img});
background-size:cover;
background-position: center center;
height: 250px;
border-radius: 10px;
div{
    border-radius: 20px;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Montserrat", sans-serif;
background: #013881;
width: 170px;
height: 25px;
color: white;
@media(min-width:900px){
    font-size: 15px; 
    height: 25px;
    width: 160px;
    border-radius: 20px;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}
}
@media(min-width:900px){
    height: 180px;
    border-radius: 10px;
}
`
export const Container = styled.div`
display: flex;
flex-direction: column;
align-items: start;
justify-content: center;
padding-left: 20px;
div{
margin-top: 5px;
font-weight: 450;
font-family: 'Roboto', sans-serif;
color: black;
font-size: 12px;
display: flex;
img{
    padding-right: 10px;
    width: 15px;
}
@media(min-width:900px){
 font-size:16px;
 img{
    padding-right: 10px;
    width: 20px;
}
}
}
`
export const Destiny = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -20px;
    font-weight: bold;
font-family: 'Roboto', sans-serif;
color: #214488;
text-align: center;
div{
    padding-top:10px;
    padding-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
text-align: center;
color: white;
border-radius: 20px;
background-color: #214488;
display: inline-block;
margin-bottom: 5px;
}
@media(min-width: 900px){
    font-size: 20px;
    margin-top: -20px;
div{
    padding-top:10px;
    padding-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
text-align: center;
color: white;
border-radius: 20px;
background-color: #214488;
display: inline-block;
}
}
`
export const Button = styled.div`
margin-top: 10px;
width: 100%;
display: flex;
align-items: center;
justify-content: center;
div{
    height: 30px;
    border-radius: 20px;
    width: 250px;
    justify-content: center;
    display: flex;
    align-items: center;
    font-size: 15px;
font-family: 'Roboto', sans-serif;
background-color: #798eb7;
color: white;
cursor: pointer;
margin-bottom: 10px;
transition: background-color 0.3s ease;
&:hover {
     background-color: rgba(33, 68, 136, 1);
}
@media(min-width: 900px){
    width: 300px;
    border-radius: 20px;
    font-size: 23px;
    padding: 5px;
    margin-bottom: 10px;
}
}
`