import styled from "styled-components"
import Pack from "../Pack";
import { useContext, useState } from "react";
import UserContext from "../../../contexts/ContextApi";
import { useModal } from "../../../contexts/ModelContext";


export default function AllPackages() {

  const { packs } = useContext(UserContext);
  const { openModal } = useModal();
  const [filter, setFilter] = useState(''); // Estado para armazenar o continente selecionado

  // Função para filtrar os pacotes com base no continente
  const filteredPacks = packs.filter(pack => {
    if (!filter) return true; // Se nenhum filtro for selecionado, mostrar todos os pacotes
    return pack.continent === filter; // Assumindo que cada pack tem um campo 'continent'
  });


  return (
    <>
      <Title>CONFIRA NOSSAS PASSAGENS</Title>
      <Filtro>
        <label>
          Filtrar por continente:
          <select onChange={(e) => setFilter(e.target.value)} value={filter}>
            <option value="">Todos</option>
            <option value="EUROPA">Europa</option>
            <option value="AMÉRICA DO SUL">América do Sul</option>
            <option value="AMÉRICA DO NORTE">América do Norte</option>
            <option value="ÁSIA">Ásia</option>
            <option value="ÁFRICA">África</option>
          </select>
        </label>
      </Filtro>
      <Container id="packages">
        {filteredPacks.map((p, index) => (
          <Pack p={p} key={index} />
        ))}
      </Container>
      <Cotacao>
        <p>Não encontrou a passagem que queria?</p>
      </Cotacao>
      <Button onClick={openModal}>Peça uma cotação personalizado</Button>
    </>
  )
}

const Container = styled.div`
display: flex;
align-items: center;
flex-wrap: wrap;
justify-content: space-around;
width: 1200px;
padding-bottom: 30px;
@media (max-width: 1200px) {
    width: 100%;
  }
`
const Title = styled.h1`
margin-top: 50px;
font-weight: bold;
font-family: "Montserrat", sans-serif;
color: #535353;
font-size: 30px;
@media (max-width: 768px) {
    text-align: center;
  }
`
const Filtro = styled.div`
font-family: "Montserrat", sans-serif;
color: #535353;
font-weight: bold;
font-size: 15px;
margin-top: 10px;
select{
  margin-left: 10px;
  border-radius: 15px;
  background-color: #013881;
  color: white;
  box-sizing: border-box;
  border: 1px solid white;
  padding: 2px;
  font-family: "Montserrat", sans-serif;
}
`;

const Cotacao = styled.div`
width: 80%;
text-align: center;
margin-bottom: 10px;
padding: 5px;
border-radius: 20px;
font-weight: bold;
font-family: "Montserrat", sans-serif;
font-size: 15px;
background-color: #013881;
color: white;
`;

const Button = styled.button`
width: 50%;
margin-bottom: 30px;
padding: 5px;
border-radius: 15px;
font-weight: bold;
font-family: "Montserrat", sans-serif;
font-size: 10px;
border: none;
background-color: #798eb7;
color: white;
cursor: pointer;
transition: background-color 0.3s ease;
&:hover {
     background-color: rgba(33, 68, 136, 1);
}
;`;