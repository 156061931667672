import { BrowserRouter } from "react-router-dom";
import { GlobalStyle } from './GlobalStyle/GlobalStyle.js';
import { Route, Routes } from 'react-router-dom';
import InitialPage from "./Pages/InitialPage";
import InformationPage from "./Pages/InformationPage.js";
import AllPacks from "./Pages/AllPacks.js";
import { UserProvider } from "./contexts/ContextApi.js";
import TeamPage from "./Pages/TeamPage.js";
import CommonPage from "./Pages/CommonPage.js";
import ContactPage from "./Pages/ContactPage.js";
// import BlogPage from "./Pages/BlogPage.js";
// import BlogEdit from "./Pages/BlogEdit.js";
import Login from "./Pages/Login.js";
import WhatsAppIcon from "./Components/WhatssapIcon.js";
import CreatePacks from "./Pages/CreatePacks.js";
import Privacy from "./Components/InitialPage/Privicidade.js";
import FilteredPacksComponent from "./Components/InitialPage/Allpacks/FiltredPacksComponent.js";
import { ModalProvider } from "./contexts/ModelContext.js";
import UniquePack from "./Pages/UniquePack.js";
// import BlogText from "./Pages/BlogText.js";

export default function App() {
  return (
    <>
      <UserProvider>
        <ModalProvider>
          <BrowserRouter>
            <GlobalStyle />
            <Routes>
              <Route path="/" element={<InitialPage />} />
              <Route path="/allpacks" element={<AllPacks />} />
              <Route path="/teampage" element={<TeamPage />} />
              <Route path="/commonpage" element={<CommonPage />} />
              <Route path="/contact" element={<ContactPage />} />
              <Route path="/filtredpacks/:continent" element={<FilteredPacksComponent />} />
              <Route path="/uniquepack/:id" element={<UniquePack/>} />
              {/* <Route path="/information" element={<InformationPage />} /> */}
              <Route path="/privacy" element={<Privacy />} />
              {/* <Route path="/blogpage" element={<BlogPage />} />
            <Route path="/blogedit" element={<BlogEdit />} /> */}
              <Route path="/createpacks" element={<CreatePacks />} />
              {/* <Route path="/pagetext/:postId" element={<BlogText/>} /> */}
              <Route path="/admin" element={<Login />} />
            </Routes>
            <WhatsAppIcon />
          </BrowserRouter>
        </ModalProvider>
      </UserProvider>
    </>
  );
}
