import Pack from "./Pack";
import { Container, Title, SubTitle } from "../../GlobalStyle/PackagesCSS";

export default function Packages() {

  const packs = [
    { id: 1, destiny: "PORTUGAL", image: "https://encurtador.com.br/mAGS5", price: "R$2.650,00/pessoa", support: "Auxílio pós-venda (check-in)", bilhete: "Cartão de embarque via Whatsapp",departureTax: "Taxa de embarque inclusa", baggage: "Incluso bagagem de 23kg (porão)", ticket: "Passagem só de ida" },
    { id: 2, destiny: "ORLANDO", image: "https://www.visiteosusa.com.br/sites/default/files/styles/hero_l/public/images/hero_media_image/2021-09/95e7d963-79dc-46f5-9388-4a128a086b2a.jpeg?h=58c8a5e7&itok=c4aYEBPF", price: "R$3.050,00/pessoa", support: "Auxílio pós-venda (check-in)", bilhete: "Cartão de embarque via Whatsapp",departureTax: "Taxa de embarque inclusa", baggage: "Incluso bagagem de 23kg (porão)", ticket: "Passagens de ida e volta" },
    { id: 3, destiny: "PARIS", image: "https://encurtador.com.br/goEU1", price: "R$4.500,00/pessoa", support: "Auxílio pós-venda (check-in)", bilhete: "Cartão de embarque via Whatsapp",departureTax: "Taxa de embarque inclusa", baggage: "Incluso bagagem de 23kg (porão)", ticket: "Passagens de ida e volta" },
    { id: 4, destiny: "MADRID", image: "https://encurtador.com.br/gszBU", price: "R$4.000,00/pessoa", support: "Auxílio pós-venda (check-in)", bilhete: "Cartão de embarque via Whatsapp",departureTax: "Taxa de embarque inclusa", baggage: "Incluso bagagem de 23kg (porão)", ticket: "Passagens de ida e volta" },
    { id: 5, destiny: "ORLANDO - EXECUTIVA (ASSENTO CAMA)", image: "https://encurtador.com.br/oILU0", price: "R$8.490,00/pessoa", support: "Auxílio pós-venda (check-in)", bilhete: "Cartão de embarque via Whatsapp",departureTax: "Taxa de embarque inclusa", baggage: "Incluso bagagem de 23kg (porão)", ticket: "Passagens de ida e volta" },
    { id: 6, destiny: "NOVA YORK", image: "https://www.passagenspromo.com.br/blog/wp-content/uploads/2019/05/pontos-turisticos-de-nova-york.jpg", price: "R$3.000,00/pessoa", support: "Auxílio pós-venda (check-in)", bilhete: "Cartão de embarque via Whatsapp",departureTax: "Taxa de embarque inclusa", baggage: "Incluso bagagem de 23kg (porão)", ticket: "Passagens de ida e volta" }
  ]


  return (<>
    <Title>
      <h1>PASSAGENS <strong>RELEVANTES</strong></h1>
      <SubTitle>
        <h2>até 40% de desconto</h2>
        <h2>mais relevantes</h2>
        <h2>todas as taxas inclusas</h2>
      </SubTitle>
    </Title>
    <Container id="packages">
      {packs.map((p, index) => (<Pack p={p} key={index} />))}
    </Container>
  </>)
};