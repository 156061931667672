import styled from "styled-components";
import Header from "../Components/InitialPage/Header";
import AllPackages from "../Components/InitialPage/Allpacks/AllPacksPackges";
import Slogan from "../Components/InitialPage/Slogan";

export default function AllPacks(){
    return (<>
        <Container>
            <Header/>
            <Slogan/>
            <AllPackages/>
        </Container>
    </>)
}

const Container = styled.div`
display: flex;
flex-direction: column;
align-items: center;
`